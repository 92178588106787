import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { env, isLocal } from 'app-config';
import i18n from 'app-utils/i18n';
import { MinimalLayout, SidebarLayout, SpaceyLayout } from './Layouts';

/**
 * Page layout
 *
 * Decides on the page's layout.
 */
const PageLayout = ({ title, variant, showSearchBar, ...rest }) => {
  switch (variant) {
    case 'minimal':
      return <MinimalLayout showLogo {...rest} />;
    case 'sidebar':
      return (
        <SidebarLayout title={title} showSearchBar={showSearchBar} {...rest} />
      );
    case 'spacey':
      return (
        <SpaceyLayout title={title} showSearchBar={showSearchBar} {...rest} />
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <React.Fragment {...rest} />;
  }
};

PageLayout.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([null, 'minimal', 'sidebar', 'spacey']),
  showSearchBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
};

PageLayout.defaultProps = {
  title: null,
  showSearchBar: false,
  variant: null,
  children: null,
};

/**
 * GetPageTitle
 *
 * Constructs the page's `<title />` tag. Suffixes title with `(Environment)`
 * when not in production.
 *
 * @param title
 * @return {string}
 */
const getPageTitle = (title) =>
  [
    title,
    title && '-',
    i18n.t('glossary:maklerportal'),
    isLocal && `(${env.charAt(0).toUpperCase() + env.slice(1)})`,
  ]
    .filter(Boolean)
    .join(' ');

/**
 * Page
 *
 * The outer-most component for every page. Responsible for handling global
 * functionality. Optionally handles layout for convenience, but can be opted
 * out of for more complex cases.
 */
const Page = ({ title, layout, showSearchBar, children }) => (
  <>
    <Head>
      <title>{getPageTitle(title)}</title>
    </Head>

    <PageLayout variant={layout} title={title} showSearchBar={showSearchBar}>
      {children}
    </PageLayout>
  </>
);

Page.propTypes = {
  title: PropTypes.string,
  showSearchBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  layout: PropTypes.oneOf([null, 'minimal', 'sidebar', 'spacey']),
};

Page.defaultProps = {
  title: null,
  showSearchBar: false,
  children: null,
  layout: null,
};

export default Page;
